var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', [_c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', [_c('b-card-title', [_vm._v(" " + _vm._s(_vm.pb ? _vm.pb.no_invoice : '-') + " ")])], 1), _c('b-card-body', [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "12",
      "lg": "6",
      "xl": "6"
    }
  }, [_c('dl', {
    staticClass: "row"
  }, [_c('dt', {
    staticClass: "col-sm-4"
  }, [_vm._v(" Tanggal Invoice ")]), _c('dd', {
    staticClass: "col-sm-8"
  }, [_vm._v(" " + _vm._s(_vm.pb ? _vm.humanDate(_vm.pb.tanggal_invoice) : '-') + " ")])]), _c('dl', {
    staticClass: "row"
  }, [_c('dt', {
    staticClass: "col-sm-4"
  }, [_vm._v(" Tanggal Penerimaan ")]), _c('dd', {
    staticClass: "col-sm-8"
  }, [_vm._v(" " + _vm._s(_vm.pb ? _vm.humanDate(_vm.pb.tanggal_penerimaan) : '-') + " ")])])]), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "12",
      "lg": "6",
      "xl": "6"
    }
  }, [_c('dl', {
    staticClass: "row"
  }, [_c('dt', {
    staticClass: "col-sm-4"
  }, [_vm._v(" Supplier ")]), _c('dd', {
    staticClass: "col-sm-8"
  }, [_vm._v(" " + _vm._s(_vm.pb ? _vm.pb.supplier_id : '-') + " ")])]), _c('dl', {
    staticClass: "row"
  }, [_c('dt', {
    staticClass: "col-sm-4 text-truncate"
  }, [_vm._v(" Keterangan ")]), _c('dd', {
    staticClass: "col-sm-8"
  }, [_vm._v(" " + _vm._s(_vm.pb ? _vm.pb.keterangan : '-') + " ")])])])], 1)], 1)], 1), _c('form-wizard', {
    attrs: {
      "color": "#ff9801",
      "title": null,
      "subtitle": null,
      "finish-button-text": "Simpan",
      "back-button-text": "Sebelumnya",
      "next-button-text": "Selanjutnya"
    },
    on: {
      "on-complete": _vm.formSubmitted
    }
  }, [_c('tab-content', {
    attrs: {
      "title": "Daftar Barang"
    }
  }, [_c('validation-observer', {
    ref: "formBarang"
  }, [_c('div', {
    staticClass: "w-100 w-full"
  }, [_c('header', {
    staticClass: "mb-2"
  }, [_c('form', [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_c('b-card', {
    staticClass: "shadow-none",
    attrs: {
      "border-variant": "primary",
      "title": "Barang",
      "bg-variant": "transparent"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "Cari Barang"
    }
  }, [_vm._v("Cari Barang")]), _c('b-input-group', [_c('b-form-input', {
    attrs: {
      "placeholder": "Tekan enter untuk cari"
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        $event.preventDefault();
        return _vm.getDataBarangs();
      }
    },
    model: {
      value: _vm.caribarang,
      callback: function ($$v) {
        _vm.caribarang = $$v;
      },
      expression: "caribarang"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function ($event) {
        return _vm.getDataBarangs();
      }
    }
  }, [_vm._v(" Cari ")])], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_vm.loaded ? _c('b-table', {
    attrs: {
      "striped": "",
      "small": "",
      "hover": "",
      "responsive": "",
      "per-page": _vm.perPageBarangs,
      "items": _vm.dataBarangs,
      "fields": _vm.fieldsBarangs,
      "sort-by": _vm.sortByBarangs,
      "sort-desc": _vm.sortDescBarangs,
      "sort-direction": _vm.sortDirectionBarangs,
      "filter-included-fields": _vm.filterOnBarangs
    },
    on: {
      "update:sortBy": function ($event) {
        _vm.sortByBarangs = $event;
      },
      "update:sort-by": function ($event) {
        _vm.sortByBarangs = $event;
      },
      "update:sortDesc": function ($event) {
        _vm.sortDescBarangs = $event;
      },
      "update:sort-desc": function ($event) {
        _vm.sortDescBarangs = $event;
      },
      "filtered": _vm.onFilteredBarangs
    },
    scopedSlots: _vm._u([{
      key: "cell(penyimpanan)",
      fn: function (data) {
        return [_c('b-form-checkbox', {
          attrs: {
            "value": data.item
          },
          model: {
            value: _vm.pilihbarang,
            callback: function ($$v) {
              _vm.pilihbarang = $$v;
            },
            expression: "pilihbarang"
          }
        })];
      }
    }, {
      key: "cell(no)",
      fn: function (row) {
        return [_c('strong', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(row.index + 1) + " ")])];
      }
    }, {
      key: "cell(kode)",
      fn: function (_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.kode ? item.kode : '-') + " ")];
      }
    }, {
      key: "cell(harga_dasar)",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.harga_dasar ? _vm.formatRupiah(item.harga_dasar) : '-') + " ")];
      }
    }, {
      key: "cell(satuan)",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(item.satuan ? item.satuan.satuan : '-') + " ")];
      }
    }], null, false, 1904938239)
  }) : _vm._e()], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6",
      "lg": "2"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "No. Batch"
    }
  }, [_c('b-form-input', {
    model: {
      value: _vm.formBarang.no_container,
      callback: function ($$v) {
        _vm.$set(_vm.formBarang, "no_container", $$v);
      },
      expression: "formBarang.no_container"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6",
      "lg": "2"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Harga Beli"
    }
  }, [_c('b-form-input', {
    attrs: {
      "value": _vm.pilihbarang ? _vm.pilihbarang.harga_dasar : 0,
      "type": "number"
    },
    on: {
      "keyup": function ($event) {
        _vm.formBarang.harga = _vm.formatRupiah(_vm.formBarang.harga);
      }
    },
    model: {
      value: _vm.formBarang.harga,
      callback: function ($$v) {
        _vm.$set(_vm.formBarang, "harga", $$v);
      },
      expression: "formBarang.harga"
    }
  })], 1)], 1), _vm.konvs.length > 0 ? _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6",
      "lg": "2"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Jumlah"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "nilai",
      "rules": "required|positive"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref4) {
        var errors = _ref4.errors;
        return [_c('b-form-input', {
          attrs: {
            "type": "number",
            "state": errors.length > 0 ? false : null
          },
          on: {
            "keyup": function ($event) {
              _vm.formBarang.nilai = _vm.formatRupiah(_vm.formBarang.nilai);
            }
          },
          model: {
            value: _vm.formBarang.nilai,
            callback: function ($$v) {
              _vm.$set(_vm.formBarang, "nilai", $$v);
            },
            expression: "formBarang.nilai"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 1209592277)
  })], 1)], 1) : _vm._e(), _vm.konvs.length > 0 ? _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6",
      "lg": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Pilih Satuan Konversi"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "jumlah",
      "rules": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref5) {
        var errors = _ref5.errors;
        return [_c('v-select', {
          attrs: {
            "reduce": function (option) {
              return option.value;
            },
            "options": _vm.konvs,
            "label": "text"
          },
          model: {
            value: _vm.formBarang.id_satuan,
            callback: function ($$v) {
              _vm.$set(_vm.formBarang, "id_satuan", $$v);
            },
            expression: "formBarang.id_satuan"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 1537844952)
  })], 1)], 1) : _vm._e(), _vm.konvs.length > 0 ? _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6",
      "lg": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Jumlah Hasil Konversi"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "jumlah",
      "rules": "required|positive"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref6) {
        var errors = _ref6.errors;
        return [_c('b-input-group', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "state": errors.length > 0 ? false : null
          },
          on: {
            "keyup": function ($event) {
              _vm.formBarang.jumlah = _vm.formatRupiah(_vm.formBarang.jumlah);
            }
          },
          model: {
            value: _vm.formBarang.jumlah,
            callback: function ($$v) {
              _vm.$set(_vm.formBarang, "jumlah", $$v);
            },
            expression: "formBarang.jumlah"
          }
        })], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 2338136672)
  })], 1)], 1) : _vm._e(), _vm.konvs.length == 0 || null ? _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6",
      "lg": "2"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Jumlah"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "jumlah",
      "rules": "required|positive"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref7) {
        var errors = _ref7.errors;
        return [_c('b-input-group', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "state": errors.length > 0 ? false : null
          },
          model: {
            value: _vm.formBarang.jumlah,
            callback: function ($$v) {
              _vm.$set(_vm.formBarang, "jumlah", $$v);
            },
            expression: "formBarang.jumlah"
          }
        })], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 2605239100)
  })], 1)], 1) : _vm._e(), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6",
      "lg": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Satuan Terkecil",
      "for": "satuan"
    }
  }, [_vm._v(" " + _vm._s(this.pilihbarang ? this.pilihbarang.satuan.satuan : "-") + " ")])], 1)], 1), _c('b-button', {
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.addBarang($event);
      }
    }
  }, [_vm._v(" Add ")])], 1)]), _c('hr'), _c('b-col', {
    staticClass: "d-flex justify-content-end",
    attrs: {
      "sm": "12",
      "md": "12",
      "xl": "12"
    }
  }, [_c('h2', {
    staticClass: "text-primary text-right"
  }, [_vm._v(" Total: "), _c('strong', [_vm._v("Rp " + _vm._s(_vm.formatRupiah(_vm.sum)))])])]), _c('footer', [_c('b-table', {
    attrs: {
      "striped": "",
      "hover": "",
      "responsive": "",
      "per-page": 300,
      "current-page": 1,
      "fields": _vm.tableFields,
      "items": _vm.selectedBarangs,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "sort-direction": _vm.sortDirection,
      "filter": _vm.filter,
      "filter-included-fields": _vm.filterOn
    },
    on: {
      "update:sortBy": function ($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function ($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function ($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function ($event) {
        _vm.sortDesc = $event;
      },
      "filtered": _vm.onFiltered
    },
    scopedSlots: _vm._u([{
      key: "cell(no)",
      fn: function (row) {
        return [_c('strong', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(row.index + 1) + " ")])];
      }
    }, {
      key: "cell(total)",
      fn: function (_ref8) {
        var item = _ref8.item;
        return [_vm._v(" " + _vm._s(_vm.formatRupiah(item.total)) + " ")];
      }
    }, {
      key: "cell(harga_dasar)",
      fn: function (_ref9) {
        var item = _ref9.item;
        return [_vm._v(" " + _vm._s(item.harga_dasar != null ? _vm.formatRupiah(item.harga_dasar) : 0) + " ")];
      }
    }, {
      key: "cell(jumlah)",
      fn: function (_ref10) {
        var item = _ref10.item;
        return [_vm._v(" " + _vm._s(_vm.formatRupiah(item.jumlah)) + " ")];
      }
    }, {
      key: "cell(harga)",
      fn: function (_ref11) {
        var item = _ref11.item;
        return [_vm._v(" " + _vm._s(_vm.formatRupiah(item.harga)) + " ")];
      }
    }, {
      key: "cell(actions)",
      fn: function (row) {
        return [_c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "title": "'Urungkan'",
            "size": "sm",
            "rounded": "",
            "variant": "danger"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.removeBarang(row);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "XIcon"
          }
        })], 1)];
      }
    }])
  })], 1)], 1)])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }